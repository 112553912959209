<template>
  <div :class="['card__section',{'card__section--collapse' : collapse}]">
		<slot></slot>
	</div>
</template>

<script>
export default {
	props: {
		collapse: Boolean,
	},
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/breakpoints.scss';

.card .card__section{
	border-bottom: var(--card-section-border);
	padding: var(--card-mobile-padding);
	width: calc(100% - (var(--card-mobile-padding) * 2));
	
	@include breakpoint(sm){
		padding: var(--card-padding);
		width: calc(100% - (var(--card-padding) * 2))
	}
}
.card--size-xlarge{
	.card__section{
		padding: var(--card-mobile-padding);
		width: calc(100% - (var(--card-mobile-padding) * 2));
		
		@include breakpoint(sm){
			padding: var(--card-xlarge-padding);
			width: var(--card-xlarge-width);
		}
	}
}
.card--size-large{
	.card__section{
		padding: var(--card-mobile-padding);
		width: calc(100% - (var(--card-mobile-padding) * 2));

		@include breakpoint(sm){
			padding: var(--card-large-padding);
			width: var(--card-large-width);
		}
	}
}
.card--size-small{
	.card__section{
		padding: var(--card-mobile-padding);
		width: calc(100% - (var(--card-mobile-padding) * 2));

		@include breakpoint(sm){
			padding: var(--card-small-padding);
			width: var(--card-small-width);
		}
	}
}
.card--size-xsmall{
	.card__section{
		padding: var(--card-mobile-padding);
		width: calc(100% - (var(--card-mobile-padding) * 2));

		@include breakpoint(sm){
			padding: var(--card-xsmall-padding);
			width: var(--card-xsmall-width);
		}
	}
}
.card .card__section:last-child{
	border-bottom: none;
}
.card .card__section--collapse{
	padding: 0!important;
	width: 100%!important;
	overflow: hidden;
	border-radius: var(--card-border-radius) var(--card-border-radius) 0 0;
}
</style>

<!-- Used in : AR, DS -->
